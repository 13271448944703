import React, { useCallback } from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import { setWidthRestrictions } from '../../../services/resizeWindow';
import { withWixSDKWidget } from '../../../services/withWixSDK';
import {
  IWixSDKContext,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';

import { withProviders } from '../../../contexts/main/withProviders';
import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { withChallengesListData } from '../../../contexts/ChallengesListDataProvider/withChallengesListData';
import { withSettingsEvents } from '../../../contexts/SettingsEvents/SettingsEvents';
import { withLocation } from '../../../contexts/Location/withLocation';
import { withPaidPlans } from '../../../contexts/PaidPlans/paidPlansContext';
import { applyProviders } from '../../../services/applyProviders';

import { ChallengesList } from './ChallengesList';
import { Spinner } from '../../../components-shared/Spinner';

import { IChallengeListStateProps } from './interfaces';
import { filterChallenges, orderChallenges } from './utils';
import { classes, st } from './Widget.st.css';
import challengeSettings, {
  CategoriesTabsChosenCategoryDefault,
} from '../settingsParams';

import { IWixStatic } from '@wix/native-components-infra/dist/src/types/wix-sdk';
import {
  getCategoriesSetup,
  filterChallengesByCategory,
} from '../../../selectors/categories';
import loadable from '@loadable/component';

const CategoriesMenu = loadable(
  () => import('./CategoriesMenu/CategoriesMenu'),
);

const EmptyList = loadable(() => import('./EmptyList/EmptyList'));

export const ChallengeList: React.FC<
  IChallengeListStateProps & IWixSDKContext
> = ({
  challengesListData,
  categoriesData,
  Wix,
  editorLoaded,
  isPage,
  goToPage,
}) => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { isEditor, isMobile } = useEnvironment();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isEditorLoaded, setIsEditorLoaded] = React.useState<boolean>(false);
  const [currentCategory, setCurrentCategory] = React.useState<string>(
    CategoriesTabsChosenCategoryDefault,
  );

  const {
    isCategoriesEnabled,
    chosenCategoryFromSettings,
    isDefaultCategory,
    isExistingInDBCategory,
    isAddonWithCategories,
  } = getCategoriesSetup(
    'data',
    experiments,
    settings,
    categoriesData?.categories,
    isPage,
  );
  const isShowCategoriesMenu = isPage || isAddonWithCategories;
  const isUseChosenCategoryFromSettings =
    !isPage &&
    chosenCategoryFromSettings &&
    !isDefaultCategory &&
    isExistingInDBCategory;

  React.useEffect(() => {
    let removeResizeListener: () => void;

    if (isEditor && Wix) {
      removeResizeListener = setWidthRestrictions(Wix as IWixStatic);
    }

    setIsEditorLoaded(editorLoaded);

    return () => {
      if (isEditor && Wix) {
        removeResizeListener();
      }
    };
  }, []);

  const onCategoryChange = useCallback((categoryId: string) => {
    setCurrentCategory(categoryId);
  }, []);

  const filteredChallenges = filterChallenges(
    challengesListData?.memberChallenges || [],
    settings,
  );
  const filteredChallengesByCategory = filterChallengesByCategory(
    filteredChallenges || [],
    isUseChosenCategoryFromSettings
      ? chosenCategoryFromSettings
      : currentCategory,
  );
  const orderedChallenges = orderChallenges(
    isCategoriesEnabled ? filteredChallengesByCategory : filteredChallenges,
    settings,
  );

  const TitleTag = settings.get(challengeSettings.listTitleTag) || 'h1';

  return (
    <div
      data-hook="challenge-list-wrapper"
      className={st(classes.root, {
        mobile: isMobile,
        titleAlignment: settings.get(challengeSettings.listTitleAlignment),
      })}
    >
      {isLoading && (
        <div className={classes.loader}>
          <Spinner />
        </div>
      )}

      {settings.get(challengeSettings.dpListTitle) ? (
        <TitleTag className={classes.listTitle} data-hook="card-title">
          {settings.get(challengeSettings.listTitleText)}
        </TitleTag>
      ) : null}

      {isCategoriesEnabled && isShowCategoriesMenu ? (
        <CategoriesMenu
          categories={categoriesData.categories || []}
          onChange={onCategoryChange}
        />
      ) : null}

      {orderedChallenges?.length ? (
        <ChallengesList
          challenges={orderedChallenges}
          isEditorLoaded={isEditorLoaded}
          changeIsLoading={() => {
            setIsLoading(!isLoading);
          }}
        />
      ) : (
        <EmptyList />
      )}
    </div>
  );
};

ChallengeList.displayName = 'ChallengesList';

export default applyProviders(ChallengeList, [
  withProviders,
  withLocation,
  withSettingsEvents,
  withGeneralData,
  withChallengesListData,
  withPaidPlans,
  withWixSDKWidget,
]);
